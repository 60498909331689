import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/views/MainPage";
const routes = [
	{
		path: "/",
		name: "Main",
		component: MainPage
	}
];
export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes
});
